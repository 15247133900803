.contact{
    @apply py-16 md:py-24 lg:py-32;
    position: relative;

    .title{
        @apply text-center w-full lg:w-2/5 mx-auto;
    }
    .contact-layout{
        @apply flex flex-col xl:flex-row items-start justify-between mt-8 md:mt-16;
    }
    .contact-blocks{ 
        @apply flex flex-col items-center justify-around 2xl:justify-between flex-wrap w-full xl:w-1/3;
    }
    .contact-form{
        @apply w-full lg:w-4/5 xl:w-3/5 2xl:w-1/2 mx-auto mt-8 lg:mt-0;
        .form-group{
            @apply flex flex-col mb-2 md:mb-6;
            label{
                @apply font-semibold text-base md:text-lg mb-2;
            }
            input,textarea{
                @apply  py-3 px-4  md:py-4 rounded-xl bg-gray-100;
               
                &:focus{
                    outline: none;
                }
            }
            textarea{
                resize: none;
                height: 120px;
            }
            .err{
                @apply mt-1 text-xs md:text-sm text-red-500 font-medium; 
            }
            
        }
        button{
            @apply bg-emerald-600 w-full lg:w-30 py-3 md:py-4 rounded-xl text-white font-medium;
            background-color: #65a39f;
            @apply bg-yellow-400 text-black;
            i{
                @apply ml-2 text-white text-sm;
                @apply text-white ml-2 text-sm text-black;
            } 
            transition: .3s ease;
            &:hover{
                // @apply bg-emerald-700;
            }
        }
    }
}